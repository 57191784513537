import {$, $$, empty, html_chargement_en_cours} from "../../core/js/Dom.ts";
import {$get} from "../../core/js/Ajax.ts";
import {fadeOutFadeIn} from "../../core/js/Animate.ts";
import {generateModale} from "../../core/js/Modal.ts";
import {createDataTables} from "../../core/js/DataTables.ts";
import {initSelects} from "../../core/js/Select.ts";

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.dataset.url?.startsWith("stat")) {

        const stats = new Stats("tabpanel-manifestation-panel")
        $$('.fr-tabs__tab').forEach((element) => {
            element.addEventListener('click', (e) => {
                if (element.classList.contains("disabled")) {
                    e.preventDefault();
                    return;
                }
                stats.onglet = element.getAttribute('aria-controls')!
                stats.get_panel()
            });
        });
        $$('.go_get_panel').forEach((element) => {
            element.addEventListener('change', () => {
                stats.get_panel();
            });
        });
        $('#btn_unique_btn')?.addEventListener('click', (e) => {
            e.preventDefault();
            fadeOutFadeIn($('#btn_double_div'), $('#btn_unique_div')).finally(() => {
                stats.type = "unique";
                stats.get_panel();
            })
            $$(".onglet_non_comparable").forEach(element => {
                element.classList.remove("disabled")
            });
        });
        $('#btn_double_btn')?.addEventListener('click', function (e) {
            e.preventDefault();
            fadeOutFadeIn($('#btn_unique_div'), $('#btn_double_div')).finally(() => {
                stats.type = "double";
                stats.get_panel();
            })
            $$(".onglet_non_comparable").forEach(element => {
                element.classList.add("disabled")
            });
        });
    }
})

function exportCellule(element) {
    var url = element.getAttribute('data-url');
    fetch(url)
        .then(function(response) {
            if (response.ok) {
                // Traiter la réponse ici
                console.log('Requête GET réussie :', response);
            } else {
                console.error('Erreur lors de la requête GET : ' + response.status);
            }
        })
        .catch(function(error) {
            console.error('Erreur lors de la requête GET : ' + error.message);
        });
}

class Stats {
    private _onglet: string
    private _type: "unique" | "double" = "unique"

    constructor(onglet: string) {
        this._onglet = onglet

        this.get_panel()
    }


    set onglet(value: string) {
        this._onglet = value;
    }

    set type(value: "unique" | "double") {
        this._type = value;
    }
    async get_datatable_detail_service(event) {
        event.preventDefault()
        const spinner = html_chargement_en_cours()
        const modalId = `datatable_detail_service-modal`
        generateModale(modalId, {
            title: "Détail des services consultés",
            content: spinner,
            title_in_header: true,
        })
        $get(event.target.dataset.href).then(response => {
            spinner.remove()
            const content = $(`#datatable_detail_service-modal-modal__content`)
            if (content) {
                content.innerHTML = response.body
                this.init_Datatable('datatable-service-consulte')
                $$('td.cellule-stat-exportable').forEach(function(element) {
                    element.addEventListener('click', function() {
                        exportCellule(element);
                    });
                });
            }
        })
    }



    async init_Datatable(id: string ){
        createDataTables(`#${id}`, {
            language: {
                emptyTable: "Aucune donnée",
            },
            paging: false, info: false, dom: 'Bfrtip', buttons: {
                buttons: [{
                    extend: 'csv', text: "Télécharger en csv", className: 'fr-btn fr-mb-1w supprimer_class', exportOptions: {
                    }
                }, {
                    extend: 'excelHtml5', text: "Télécharger en excel", className: 'fr-btn fr-mb-1w supprimer_class', exportOptions: {
                    }
                }, {
                    extend: 'print', text: "Imprimer", className: 'fr-btn fr-mb-1w  supprimer_class', exportOptions: {
                    }
                },],
            },
            colReorder: true,
        })
    }

    async get_panel(filtre="") {
        const ongletElement = $(`#${this._onglet}`)
        if (ongletElement) {
            empty(ongletElement)
            ongletElement.appendChild(html_chargement_en_cours("Calcul des statistiques en cours..."))
            if (this._type === "unique") {
                let instance = $<HTMLInputElement>('#dept')
                let annee = $<HTMLInputElement>('#annee')

                if (instance && annee) {
                    const instanceValue = instance.value
                    const anneeValue = annee.value
                    const url_final_instance = `${window.Openscop.url_stat_ajax}?id=${this._onglet}&instance=${instanceValue}&annee=${anneeValue}${filtre}`;
                    $get(url_final_instance).then(response => {
                        empty(ongletElement)
                        ongletElement.innerHTML = response.body
                        if (this._onglet === "tabpanel-instruction-panel") {
                            $$(".get-datatable-service-consulte")?.forEach(element => {
                                element.addEventListener('click', (event) => this.get_datatable_detail_service(event));
                            });
                        }
                        const filtresComparaison = document.querySelectorAll('.filtre-comparaison-stat');
                        if (this._onglet === "tabpanel-service-panel" || this._onglet === "tabpanel-prescription-panel") {
                            filtresComparaison.forEach(element => {
                                element.setAttribute('disabled', 'true');
                            });
                        } else {
                            filtresComparaison.forEach(element => {
                                element.removeAttribute('disabled');
                            });
                        }
                        if(ongletElement.querySelector('.stat-filter')){
                            initSelects(ongletElement.querySelector('.stat-filter'))
                            ongletElement.querySelector('.btn-actualise-filtre').addEventListener('click', () => {
                                let params = '';
                                const selects = ongletElement.querySelectorAll('.stat-filter select');
                                selects.forEach((select: HTMLSelectElement) => {
                                    params += `&${select.id}=${select.value}`;
                                });
                                this.get_panel(params);
                            })
                        }
                    })
                }
            } else {
                let instance_d = $<HTMLSelectElement>('#dept_d')
                let instance_s = $<HTMLSelectElement>('#dept_d_s')
                let anneeu = $<HTMLInputElement>('#anneeu')
                if (instance_d && instance_s && anneeu) {
                    const instance_dValue = instance_d.value
                    const instance_sValue = instance_s.value
                    const anneeuValue = anneeu.value
                    const url_final_instance_d = `${window.Openscop.url_stat_ajax}?id=${this._onglet}&instance=${instance_dValue}&annee_unique=${anneeuValue}`;
                    const url_final_instance_s = `${window.Openscop.url_stat_ajax}?id=${this._onglet}&instance=${instance_sValue}&annee_unique=${anneeuValue}`;

                    const {body: reponseu} = await $get(url_final_instance_d)
                    const {body: responsed} = await $get(url_final_instance_s)

                    ongletElement.innerHTML = `<div class="fr-grid-row--gutters fr-grid-row">
                        <div class="fr-col-6">
                            <h2>${instance_d.options[instance_d.selectedIndex].text}</h2>
                            ${reponseu}
                        </div>
                        <div class="fr-col-6">
                            <h2>${instance_s.options[instance_s.selectedIndex].text}</h2>
                            ${responsed}
                        </div>
                    </div>`
                }
                if(ongletElement.querySelector('.stat-filter')){
                    $$(".stat-filter").forEach(element => {
                        element.classList.remove("d-flex")
                        element.classList.add("d-none")
                    });
                }
            }
        }
    }
}